import React from 'react'
import {
    Title,
    Paragraph,
    BackButton
} from '../../components'
import { Container } from './styles'
import { useHistory } from 'react-router-dom'
import  Button  from '../../components/Button'

export default function (){
    const history = useHistory()

    const buttons = [
        
        {
            title: "Técnica da colocação da máscara de proteção respiratória tipo N95, N99, N100, PFF2 ou PFF",
            url: "/colocacaoMascaraRespiratoria"
        },
        {
            title: "Técnica da retirada da máscara de proteção respiratória tipo N95, N99, N100, PFF2 ou PFF3 ",
            url: "/retiradaMascaraRespiratoria"
        }
    ]

    return (
        <Container>
            <Title>Máscara</Title>
            
            <Paragraph>
                Deve ser utilizada quando o profissional for executar os seguintes procedimentos: induzir a tosse, intubação ou aspiração traqueal, ventilação invasiva e não invasiva, ressuscitação cardiopulmonar, ventilação manual antes da intubação, indução de escarro. Em coleta de amostra nasotraqueal em paciente com suspeita ou infecção confirmada pelo Sars-CoV-2, que pode gerar aerossóis, os profissionais devem usar a máscara de proteção respiratória com eficácia mínima na filtração de 95% de partículas de até 0,3 µ (tipo N95, N99, N100, PFF2 ou PFF3). (FENL, 2017; OLIVEIRA et al., 2020).
            </Paragraph>
            <Paragraph>
                Visando minimizar a contaminação da máscara N95 ou equivalente, se houver disponibilidade, pode ser usado um protetor facial (face shield) e, se a máscara estiver íntegra, limpa e seca, pode ser usada várias vezes durante o mesmo plantão pelo mesmo profissional (até 12 horas ou conforme definido pela Comissão de Controle de Infecção Hospitalar – CCIH – do serviço de saúde). (ANSELL, 2017; ANVISA, 2020; COFEN, 2020; HELLEWELL et al., 2020). 
            </Paragraph>

            {
                buttons.map((button, index) => (
                    <Button
                        key={index}
                        color="success"
                        outlined="outlined"
                        onClick={() => history.push(button.url)}
                    >
                        {button.title}
                    </Button>
                ))
            }

            <BackButton />
        </Container>
    )
}