import styled from 'styled-components'

const Container = styled.h1`
    font-size: 22px;
    text-align: center;
    border-bottom: 3px solid #CECECE;
    border-color: #2581CE;
    color: #2581CE;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 25px;
`

export {
    Container
}