import React from 'react'
import {
    Title,
    BackButton,
    List,
} from '../../components'
import { Container } from './styles'


import img68 from '../../assets/img/img68.png'
import img69 from '../../assets/img/img69.png'
import img70 from '../../assets/img/img70.png'

export default function (){

    const items = [
        "Vista o avental ou capote primeiramente pelas mangas, ajustando as amarras nas costas e cintura.",
        (<img className="img-fluid mb-3 col-12" alt="Óculos" src={img68} />),
        "Certifique-se de que o tronco esteja totalmente coberto, bem como os braços e os punhos.",
        (<img className="img-fluid mb-3 col-12" alt="Óculos" src={img69} />),
        "Prenda o avental  na parte superior com um laço, em seguida prenda a parte da cintura.",
        (<img className="img-fluid mb-3 col-12" alt="Óculos" src={img70} />)
    ]

    return (
        <Container>
            <Title>Técnica de colocação do avental</Title>
            
            <List items={items} />

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/6J1q3p0pq54" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            

            <BackButton/>
        </Container>
    )
}