import React from 'react'
import {
    Title,
    Paragraph,
    BackButton
} from '../../components'
import { Container } from './styles'
import { useHistory } from 'react-router-dom'
import  Button  from '../../components/Button'

export default function (){
    const history = useHistory()

    const buttons = [
        
        {
            title: "Técnica de colocação do avental",
            url: "/colocacaoAvental"
        },
        {
            title: "Técnica de retirada do avental ",
            url: "/retiradaAvental"
        }
    ]

    return (
        <Container>
            <Title>Avental </Title>

            <Paragraph>
                O avental deve ter gramatura mínima de 30 g/m2; seu uso evita a contaminação da pele e roupa do profissional. O avental impermeável deve ser usado para atendimentos de pessoas suspeitas ou contaminadas pelo SarsCov-2 (COFEN, 2020; OLIVEIRA et al., 2020).
            </Paragraph>
            <Paragraph>
                O avental deve ter mangas longas, punho de malha ou elástico e abertura posterior. Além disso, deve ser confeccionado com material de boa qualidade, atóxico.
            </Paragraph>
            <Paragraph>
                Sugere-se que o avental, impermeável, após uso, seja considerado contaminado, devendo ser removido e descartado como resíduo infectante após a execução do procedimento do paciente com Covid-19 antes de sair do quarto de isolamento. (ANVISA, 2020; COFEN, 2020; HELLEWELL et al., 2020).
            </Paragraph>

            {
                buttons.map((button, index) => (
                    <Button
                        key={index}
                        color="success"
                        outlined="outlined"
                        onClick={() => history.push(button.url)}
                    >
                        {button.title}
                    </Button>
                ))
            }

            <BackButton />
        </Container>
    )
}