import React from 'react'

import {
    Container
} from './styles'

export default function ({ children }){
    return (
        <Container>
            { children }
        </Container>
    )
}