import React from 'react'

import {
    Container
} from './styles.js'

export default function ({ outlined, color, onClick, children}){
    return (
        <Container className="p-3 text-center">
            <button 
                className="btn btn-deafult btn-success btn-block btn-covid" 
                onClick={onClick}
            >
            { children }
            </button>
        </Container>
    )
}