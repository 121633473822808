import styled from 'styled-components'

const Container = styled.div``

const Author = styled.p`
    color: white;
`

const Background = styled.div`
    background-color: #2581CE;
`

export {
    Container,
    Author,
    Background
}