import React from 'react'

import {
    Title, 
    Paragraph, 
    List, 
    SubTitle, 
    BackButton
} from '../../components'
import { 
    Container,
    InlineImages
 } from './styles'

import img8 from '../../assets/img/img8.jpg'
import img9 from '../../assets/img/img9.jpg'
import img10 from '../../assets/img/img10.jpg'

export default function (){
    return (
        <Container>
            <Title>Pandemia pela infecção pelo Sars-Cov-2</Title>
            
            <Paragraph>
                Em 31/12/2019, o escritório da Organização Mundial de Saúde (OMS) na China foi informado sobre casos de Pneumonia de etiologia desconhecida, na cidade Wuhan, província de Hubei, na China, com características semelhantes às de Pneumonias Virais. Em 07 de janeiro de 2020, foi feito o isolamento viral por meio de técnica de biologia molecular de secreções respiratórias, realizando o sequenciamento genético e um novo Coronavírus foi identificado, que no início foi temporariamente nomeado de 2019- nCoV e, em 11 de fevereiro de 2020, foi denominado de SARS-CoV-2. (GUAN et al.,2020).
            </Paragraph>

            <Paragraph>
                Este novo coronavírus é o agente causal da doença, denominada COVID 19 em alusão à palavra em inglês: Coronavirus Disease e ano do aparecimento inicial desta: 2019.
            </Paragraph>

            <Paragraph>
                Os Coronavírus pertencem a um grupo taxonômico de vírus que causam infecções respiratórias e podem acometer humanos e outros animais. Descobertos inicialmente em aves domésticas na década de 1930, vários Coronavírus causam doença respiratória, gastrintestinal, hepática e neurológica nos animais. Apenas sete coronavírus, sabidamente, causam doença nos humanos, desde sintomas leves de resfriado comum até infecções graves do aparelho respiratório, como Pneumonias e Síndrome Respiratória Aguda Grave “SARS”. A esse respeito, segue a figura 1.
            </Paragraph>

            <Title>Imagem</Title>

            <Paragraph>
                O genoma do CoV é envelopado, constituído por uma fita simples de RNA, que apresenta espículas na sua superfície que infectam uma grande variedade de animais e humanos. (MUNSTER et al., 2020). A frequência de eventos de transmissão de patógenos de animais para humanos tem tido um aumento nas últimas décadas, reconhecidamente denominada de zoonoses. (ZUMLA et al., 2016).
            </Paragraph>

            <Paragraph>
                Os três coronavírus que causam infecção respiratória mais graves nos humanos, por vezes fatais, são considerados zoonoses e estão descritos a seguir:
            </Paragraph>

            <List items={[
                "SARS-CoV-2 é o novo betacoronavírus identificado em 31/12/2019 como agente etiológico da doença pelo coronavírus 2019 (Covid-19) descrito em Wuhan, na China;",
                "Mers-CoV foi identificado em 2012 como a causa da síndrome respiratória do Oriente Médio (MERS);",
                "SARS-CoV foi identificado em 2002 como a causa de um surto da Síndrome Respiratória Aguda Grave (SARS)."
            ]} />

            <Paragraph>
                Acredita-se que a Covid-19 (SARS-CoV-2) tenha origem em mamíferos chirópteros (morcegos), tendo em vista que foram reportados a um comércio local de animais em Wuhan, bem como sua íntima semelhança genética com coronavírus infectantes deste gênero. (CHEN, 2020).
            </Paragraph>

            <Paragraph>
                A COVID-19 apareceu pela primeira vez na China, em 2019, na cidade de Wuhan e foi responsável pelo surgimento de uma infecção respiratória, conhecida como COVID-19, que pode variar desde uma simples gripe até complicações muito graves, como pneumonia, colocando a vida dos indivíduos infectados em risco. (BELASCO &amp; FONSECA, 2020; CHAVES &amp; BELEI, 2020).
            </Paragraph>

            <Paragraph>
                As autoridades sanitárias chinesas tornaram público o surto e divulgaram as medidas em saúde pública em seu país, incluindo vigilância epidemiológica contínua, com investigações clínicas que conduziram ao fechamento do mercado de Wuhan para desinfecção do ambiente. Em 30 de janeiro de 2020, a Organização Mundial da Saúde (OMS) declarou a COVID-19 como Emergência de Saúde Pública de Interesse Mundial. (MUNSTER et al., 2020; WILSON &amp; CHEN, 2020).
            </Paragraph>

            <Paragraph>
                Especificamente no Brasil, em 03 de fevereiro de 2020, o Ministério da Saúde (MS) declarou a emergência em Saúde Pública de Importância Nacional (ESPIN) e, em seguida, sancionou a lei n° 13.979 de 6/2/2020, que dispõe sobre as medidas para enfrentamento da emergência de importância de caráter nacional e internacional, decorrente da covid-19. (LIMA et al., 2020).
            </Paragraph>

            <Paragraph>
                Em 6 de fevereiro, o MS informou que havia nove casos suspeitos de COVID-19 em cinco estados diferentes do país, sendo que, destes, nenhum caso foi confirmado. No dia 24 de fevereiro de 2020, o Ministério da Saúde informou que havia quatro casos suspeitos de infecção pelo SARS-CoV-2 e que outros 54 casos suspeitos foram descartados. Em 25 de fevereiro de 2020, o Hospital Israelita Albert Einstein registrou a notificação do primeiro caso.
            </Paragraph>

            <Paragraph>
                No mês de março, reconhecia-se o estado de pandemia. O Ministério da Saúde do Brasil, através do então ministro Luiz Henrique Mandetta, posicionou-se de acordo com as orientações da Organização Mundial da Saúde (OMS) ao adotar o isolamento social chamado de isolamento horizontal, porém o presidente do Brasil, Jair Bolsonaro, defendeu o isolamento somente para o grupo de risco, chamado de isolamento vertical.
            </Paragraph>

            <Paragraph>
                Em 17 de março de 2020, o Estado de São Paulo confirmou a primeira morte, um homem de 62 anos que estava internado em hospital particular. A prefeitura de São Paulo, em 4 de maio, divulgou dados indicativos de que a maior quantidade de mortes por COVID-19 ou casos suspeitos concentrava-se, em sua maioria, em comunidades, cortiços, conjuntos ou núcleos habitacionais.
            </Paragraph>

            <Paragraph>
                Segundo a OMS, a COVID-19 é transmitida por secreções respiratórias e saliva, sendo indicado ter alguns cuidados para evitar a contaminação e transmiti-la, pois, segundo estudos da organização, cada indivíduo contaminado tem potencial para transmitir a doença para até oito pessoas. Os cuidados recomendados pela OMS são: cobrir a boca ao tossir ou espirrar, lavar as mãos regularmente e evitar tocar o rosto, principalmente na região dos olhos, nariz e boca, conforme a figura 2, exposta a seguir. (BELASCO &amp; FONSECA, 2020, CHAVES &amp; BELLEI, 2020; MUNSTER et al., 2020).
            </Paragraph>

            <Paragraph>
                O aparecimento de mutações é um acontecimento natural e aguardado dentro do processo de evolução dos vírus. A partir da caracterização genômica inicial do SARS-CoV-2, este vírus se fragmentou em distintos grupos genéticos ou clados. Certamente, algumas mutações características definem os grupos genéticos virais (também denominadas linhagens) que na atualidade ocorrem no mundo. Devido a diversos processos de pequenas evoluções e pressões de seleção, podem surgir algumas mutações a mais, propiciando dessemelhanças dentro de cada grupo genético (chamadas variantes). É relevante referir que as denominações clado, linhagem, variante etc. são arbitrárias não correspondendo a uma hierarquia taxonômica oficial. (OPAS, 2021)
            </Paragraph>

            <InlineImages className="row">
                <div className="col-6 col-sm-4 text-center m-auto">
                    <img src={img8} alt="Imagem" className="img-fluid" />
                </div>
                <div className="col-6 col-sm-4 text-center m-auto">
                    <img src={img9} alt="Imagem" className="img-fluid" />
                </div>
                <div className="col-6 col-sm-4 text-center m-auto">
                    <img src={img10} alt="Imagem" className="img-fluid" />
                </div>
            </InlineImages>

            <Paragraph>
                A transmissão por gotículas respiratórias pode ocorrer quando uma pessoa está em contato próximo (na faixa de um metro) com uma pessoa infectada que tem sintomas respiratórios (por ex., tosse ou espirro) ou que está falando ou cantando; nessas circunstâncias, as gotículas respiratórias que incluem o vírus podem atingir a boca, nariz ou olhos de uma pessoa suscetível, podendo resultar em infecção.
            </Paragraph>

            <Paragraph>
                A transmissão por aerossóis é definida como a disseminação de um agente infeccioso causada pela dispersão de núcleos de gotículas (aerossóis) que continuam infecciosos quando suspensos no ar por longas distâncias e tempo.
            </Paragraph>

            <Paragraph>
                Antes da pandemia da COVID-19, a OMS já recomendava cuidados e técnicas de manipular pacientes contaminados por SARS CoV-2.
            </Paragraph>

            <Paragraph>
                Os sintomas da contaminação podem aparecer entre 2 a 14 dias após a exposição ao vírus. Isso se dá, porque a incubação do vírus no corpo sem apresentar sintoma pode levar até quatro dias e afeta vários órgãos do corpo humano. As figuras 3 e 4 e o quadro 1, na sequência, ilustram essas informações.
            </Paragraph>

            <Title>Imagem</Title>

            <SubTitle>Sinais e sintomas da COVID-19</SubTitle>

            <Paragraph>
                Febre 78,4% -> (73,6%-82,8%)
            </Paragraph>
            <Paragraph>
                Tosse 58,3% -> (51,5%-64,9%)
            </Paragraph>
            <Paragraph>
                Anosmia 52,7% -> (29,6%-75,2%)
            </Paragraph>
            <Paragraph>
                Disgeusia 43,9% -> (20,4%-68,9%)
            </Paragraph>
            <Paragraph>
                Fadiga 34% -> (27,7%-40,5%)
            </Paragraph>
            <Paragraph>
                Expectoração 23,7% -> (18,5%-29,4%)
            </Paragraph>
            <Paragraph>
                Anorexia 22,9% -> (14,3%-32,6%)
            </Paragraph>
            <Paragraph>
                Pressão/dor torácica 22,9% -> (16,3%-30,4%)
            </Paragraph>
            <Paragraph>
                Dispneia 33,9% -> (24,2%-44,3%)
            </Paragraph>
            <Paragraph>
                Mialgias 33% -> (26%-40,5%)
            </Paragraph>
            <Paragraph>
                Náuseas/Vômitos 21% -> (9%-44%)
            </Paragraph>
            <Paragraph>
                Cefaleia 15,4% -> (11,6%-19,6%)
            </Paragraph>
            <Paragraph>
                Dor de garganta 13,1% -> (7,4%-20,3%)
            </Paragraph>
            <Paragraph>
                Calafrios/Tremores 10,9% -> (5,8%-17,4%)
            </Paragraph>
            <Paragraph>
                Diarreia 9% -> (6%-12%)
            </Paragraph>
            <Paragraph>
                Rinorreia 7,3% -> (4,2%-11,3%)
            </Paragraph>
            <Paragraph>
                Dor abdominal 3% -> (2%-5%)
            </Paragraph>

            <Title>Imagem</Title>

            <Paragraph>
                A Organização Mundial da Saúde, preocupada com a disseminação da doença em nível global, faz algumas orientações com o fim de diminuir a propagação desse vírus, recomendando medidas como a higienização das mãos, de acordo com a ilustração na figura 2 anterior, antissepsia das mãos com álcool em gel 70% para a população em geral e a utilização dos equipamentos de proteção individual pelos profissionais de saúde, como apresentado na figura 5 reproduzida mais adiante (GEFEN &amp; OUSEY, 2020).
            </Paragraph>
            <Paragraph>
                Em estudo experimental, avaliaram a persistência do corona vírus em diferentes superfícies inanimadas. (KAMPF et al., 2020). O quadro 2 subsequente apresenta as superfícies em que o vírus se instala.
            </Paragraph>

            <SubTitle>Persistência da Corona vírus em diferentes superfícies inanimadas.</SubTitle>

            <Paragraph>
                Aerossóis/poeira -> 40minutos a 2 horas e 30minutos
            </Paragraph>
            <Paragraph>
                Aço inoxidável -> 48 horas
            </Paragraph>
            <Paragraph>
                Metal -> 5 dias
            </Paragraph>
            <Paragraph>
                Papelão -> 4-5 dias
            </Paragraph>
            <Paragraph>
                Vidro -> 4 dias
            </Paragraph>
            <Paragraph>
                Tubo de silicone -> 5 dias
            </Paragraph>
            <Paragraph>
                Plástico ->  5 dias
            </Paragraph>
            <Paragraph>
                Luva de látex -> 8 horas
            </Paragraph>
            <Paragraph>
                Cobre -> 4 horas
            </Paragraph>

            <BackButton />

        </Container>
    )
}