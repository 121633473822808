import React from 'react'
import {
    Title,
    BackButton
} from '../../components'
import { Container } from './styles'
import { useHistory } from 'react-router-dom'
import  Button  from '../../components/Button'

export default function (){
    const history = useHistory()

    const buttons = [
        
        {
            title: "Técnica de colocação de óculos protetores e Face Shield",
            url: "/colocacaoOculos"
        },
        {
            title: "Técnica de retirada do protetor facial (Face Shield) ",
            url: "/retiradaOculos"
        }
    ]

    return (
        <Container>
            <Title>Óculos protetores e protetor facial (Face Shield) </Title>

            {
                buttons.map((button, index) => (
                    <Button
                        key={index}
                        color="success"
                        outlined="outlined"
                        onClick={() => history.push(button.url)}
                    >
                        {button.title}
                    </Button>
                ))
            }

            <BackButton />
        </Container>
    )
}