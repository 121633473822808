import React from 'react'
import Title from '../../components/Title'
import { Container } from './styles'
import { useHistory } from 'react-router-dom'
import  {
    BackButton,
    Button,
    List,
    Paragraph,
    SubTitle
}  from '../../components'

import img100 from '../../assets/img/img100.png'

export default function (){
    const opcoes = [
        {
            title: 'Máscara',
            url: '/mascara'
        },
        {
            title: 'Máscara de proteção respiratória N95, N99, N100, PFF2 e PFF3',
            url: '/mascarasRespiratoria'
        },
        {
            title: 'Touca',
            url: '/touca'
        },
        {
            title: 'Óculos protetores e protetor facial (Face Shield)',
            url: '/oculos'
        },
        {
            title: 'Avental',
            url: '/avental'
        },
        {
            title: 'Uso da luva',
            url: '/luva'
        },
        {
            title: 'Recomendações sobre o tipo de EPI.',
            url: '/recomendacoesEPI'
        },
        {
            title: 'Algoritmos',
            url: '/algoritmosEPI'
        }
    ]

    const favorecemAdesao = [
        "Diferentes percepções sobre o risco Medo de infectar a si mesmos, suas biológico de contágio entre os profissionais da equipe.",
        "Falta de treinamento adequado sobre o uso dos EPIs e demais estratégias de enfrentamento.",
        "Diretrizes de difícil compreensão seja pelo método de transmissão, seja pela linguagem ambígua e linguagem pouco clara e pouco objetiva.",
        "Contradições entre as diretrizes locais com as nacionais e internacionais e mudanças constantes.",
        "Condições de trabalho e realidade dos serviços desfavoráveis a prática das diretrizes de enfrentamento da pandemia.",
        "Aumento da carga de trabalho e fadiga devido aos procedimentos deparamentação constantes e a limpeza adicional requerida no ambiente.",
        "Desconforto com o uso dos EPIs, sobretudo máscaras. Existência de programas de aprendizagem em serviço."
    ]

    const dificultamAdesao = [
        "Medo de infectar a si mesmos, suas famílias ou por se sentirem responsáveis pelo contágio de seus pacientes.",
        "Existência de cultura organizacional do serviço favorável a práticas de segurança do paciente e comunicação efetiva.",
        "Nível de apoio da equipe de gerentes percebido pela equipe de trabalho.",
        "Decisões compartilhadas em relação ao planejamento e organização do processo de trabalho da equipe.",
        "Realização de treinamento com o uso de metodologias ativas e participativas de ensino aprendizagem.",
        "Fornecimento de material educativo tais como cartilhas e livretos.",
        "Sentimento de distanciamento e isolamento em relação aos pacientes com risco de fortalecer estigmas."
    ]

    const history = useHistory()

    return (
        <Container>
            <Title>USO DE EQUIPAMENTO DE PROTEÇÃO INDIVIDUAL (EPIS) PELOS PROFISSIONAIS DE SAÚDE</Title>

            <Paragraph>
                A NR-06 do Ministério do Trabalho, publicada no D.O.U PARTE I, DECRETO nº 46.237 - de 18 junho de 1959, ou norma regulamentadora 6, foi criada para regulamentar o uso de Equipamentos de Proteção Individuais (EPIs), de modo que todas as empresas sejam obrigadas a adotar as mesmas medidas de proteção do funcionário em cenários semelhantes. Isso significa que todas as pessoas exercendo as mesmas funções devem ter um mesmo equipamento de proteção padronizado que leve em consideração as normas legais exigidas para essa função em específico.
            </Paragraph>
            <Paragraph>
                Em que pese a maior eficiência das medidas de proteção coletivas, o uso de EPIs é imprescindível para minimizar os riscos de contato de trabalhadores de saúde com o vírus SARS-CoV-2 (OLIVEIRA et al., 2020; SILVA et al,2020) Garantir o acesso aos EPIs recomendados a todos os trabalhadores e em quantidade e qualidade é responsabilidade do empregador, seja ele público ou privado, em regime da Consolidação das Leis do Trabalho (CLT) ou estatutário. Como também constituem obrigação do empregador o treinamento adequado dos trabalhadores, a supervisão do uso adequado e a manutenção e reposição necessários segundo o fabricante.(DE ALMEIDA, et al,2020; FILHO et al., 2020; SOARES et al,2020)
            </Paragraph>
            <Paragraph>
                É importante notar que esses EPIs precisam estar disponíveis em tamanho adequado aos usuários. Ressalta-se a necessidade do uso racional de EPI nos serviços de saúde, pois se trata de um recurso finito e imprescindível para oferecer segurança aos profissionais durante a assistência. Os tipos de equipamentos necessários para a prevenção da COVID-19 nos serviços de saúde são baseados nas tarefas executadas, mas de maneira geral, todos os EPIs devem: ser selecionados com base no risco biológico a que os trabalhadores estão expostos; estar regularizados nos órgãos certificadores e na Anvisa; ser usados adequadamente; ser higienizados e/ou descartados periodicamente, conforme recomendações técnicas e ser inspecionados, reparados e substituídos de acordo com instruções do fabricante. É importante lembrar que em nenhuma hipótese os EPIs de uso exclusivo no serviço de saúde devem ser levados para casa. Em geral, os EPIs que devem ser disponibilizados pelos serviços e utilizados pelos profissionais de saúde responsáveis pelo atendimento de casos suspeitos ou confirmados de COVID-19 são: 1) gorro; 2) óculos de proteção ou protetor facial; 3) máscara); 4) avental impermeável de mangas compridas; 5) luvas de procedimento.
            </Paragraph>
            <Paragraph>
                Com relação ao tipo de máscara, para procedimentos geradores de gotículas, o profissional precisa utilizar a máscara cirúrgica e as de proteção respiratória (respirador particulado) com eficácia mínima na filtração de 95% de partículas de até 0,3µ (tipo N95, N99, N100, PFF2 ou PFF3) sempre que realizar procedimentos geradores de aerossóis como, por exemplo, intubação ou aspiração traqueal, ventilação não invasiva, ressuscitação cardiopulmonar, ventilação manual antes da intubação, indução de escarro, coletas de amostras nasotraqueais e broncoscopias.
            </Paragraph>

            <Paragraph>
                <strong>Triagem de pacientes <br /></strong>
                Higiene de mãos; Máscara cirúrgica; 
            </Paragraph>

            <Paragraph>
                <strong>Coleta de amostras <br /></strong>
                Higiene de mãos; Avental; Máscara N95; Óculos ou protetor facial; Luvas;
            </Paragraph>

            <Paragraph>
                <strong>Assistência sem gerar aerossol <br /></strong>
                Higiene de mãos; Avental; Máscara Cirúrgica; Óculos ou protetor facil; Lucas;
            </Paragraph>

            <Paragraph>
                <strong>Assistência em procedimento gerador de aerossol <br /></strong>
                Higiene de mãos; Avental; Máscara N95; Óculos ou protetor facil; Lucas;
            </Paragraph>

            <Paragraph>
                Os profissionais da saúde nos prontos-socorros, unidades de internação, Unidade de longa permanência, Unidade de Terapia Intensiva estão na linha de frente na prestação da assistência, atendendo os indivíduos durante a pandêmica do COVID-19.
            </Paragraph>
            <Paragraph>
                Os profissionais de saúde têm chances maiores de se contaminarem diante dos frequentes procedimentos realizados nos pacientes infectados. Há ainda a possibilidade de complicações subsequentes que podem aumentar a disseminação do vírus em decorrência de certos procedimentos feitos por eles, como a aspiração traqueal e a intubação traqueal. Para que não haja a contaminação desses profissionais, é recomendada a utilização rigorosa e adequada dos EPIs (COFEN, 2020; MIRANDA et al., 2020).
            </Paragraph>
            <Paragraph>
                A Lei nº 6.514 de 22/12/1977 altera o Capítulo V do título II da CLT, estabelecendo uma série de disposições quanto à segurança e medicina do trabalho. A Portaria nº 3.214/1078, constante nesse mesmo capítulo, aprova as Normas Regulamentadoras. A NR6-EPI estabelece e define os tipos de EPIs que as empresas estão obrigadas a fornecer a seus empregados, sempre que as condições de trabalho o exigirem, a fim de resguardar a saúde e a integridade física dos trabalhadores. (NEVES et al., 2011; SANTOS et al., 2015).
            </Paragraph>
            <Paragraph>
                No Brasil, o Ministério do Trabalho (MT) exige que os empregadores forneçam os EPIs adequados para mitigar os riscos aos quais os profissionais estão expostos, bem como realizem treinamento contínuo sobre como utilizar corretamente esses equipamentos. A falta dos EPIs para os profissionais trabalhar e a utilização de forma incorreta expõem o profissional a risco biológico, o que leva ao desgaste emocional por medo de se infectar. O uso inadequado dos EPIs também pode acarretar lesões causadas por dispositivos médicos. (CARVALHO &amp; CHAVES, 2010).
            </Paragraph>

            <img className="img-fluid" src={img100} alt="COVID-APP" />

            <Paragraph>
                Para a utilização adequada dos EPIs, o profissional deve levar em consideração, não somente a eficiência necessária para o controle do risco de exposição, mas também o conforto ao portar esses materiais. Se há desconforto no uso do equipamento, há possibilidade de o profissional não o adotar e até mesmo não o incorporar na prática rotineira. (CORDEIRO et al., 2016; PACZEK et al., 2020).
            </Paragraph>
            <Paragraph>
                Preconiza-se como técnica de utilização de equipamento individual pelos profissionais da saúde: antes de iniciar a paramentação, lavar as mãos com água e sabão ou higienizar com solução alcoólica a 70%.
            </Paragraph>

            <SubTitle>Paramentação</SubTitle>

            <Paragraph>
                1º Avental ou capote<br />
                2º Máscara cirúrgica OU Máscara de proteção respiratória<br />
                3º Óculos ou protetor facial<br />
                4º Gorro ou touca* <br />
                5º Luvas<br/><br/>

                *No caso de procedimentos geradores de aerossóis.
            </Paragraph>

            <SubTitle>Desparamentação</SubTitle>

            <Paragraph>
                1º Luvas<br />
                2º Avental ou capote<br />
                3º Gorro ou touca*<br />
                4º Óculos ou protetor facial*<br />
                5º Máscara cirúrgica OU de proteção respiratória*<br /><br />
                OBS: Exceto pela máscara, remova o EPI ainda no quarto, próximo à saída, ou na antessala. Remova a máscara somente após deixar o quarto do paciente e fechar a porta.
            </Paragraph>

            <SubTitle>Fatores que favorecem a adesão</SubTitle>
            <List items={favorecemAdesao} />
            
            <SubTitle>Fatores que dificultam a adesão</SubTitle>
            <List items={dificultamAdesao} />
            {
                opcoes.map((opcao, index) => (
                    <Button
                        outlined="outlined"
                        color="success"
                        onClick={() => history.push(opcao.url)}
                        key={index}
                    >{ opcao.title }</Button>
                ))
            }

            <BackButton />
        </Container>
    )
}