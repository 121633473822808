import React from 'react'
import {
    Title,
    Paragraph,
    BackButton
} from '../../components'
import { Container } from './styles'
import { useHistory } from 'react-router-dom'
import  Button  from '../../components/Button'

export default function (){
    const history = useHistory()

    const buttons = [
        
        {
            title: "Técnica de uso da touca",
            url: "/usoTouca"
        },
        {
            title: "Técnica da retirada da touca",
            url: "/retiradaTouca"
        }
    ]

    return (
        <Container>
            <Title>Touca</Title>
            
            <Paragraph>
                A utilização da touca tem por finalidade proteger de forma dupla, tanto contra partículas que possam contaminar os profissionais, quanto da queda de cabelos ou outros componentes em materiais do trabalho.
            </Paragraph>

            {
                buttons.map((button, index) => (
                    <Button
                        key={index}
                        color="success"
                        outlined="outlined"
                        onClick={() => history.push(button.url)}
                    >
                        {button.title}
                    </Button>
                ))
            }

            <BackButton />
        </Container>
    )
}