import React from 'react'
import {
    Title,
    BackButton,
    List
} from '../../components'
import { Container } from './styles'


import img53 from '../../assets/img/img53.jpg'
import img43 from '../../assets/img/img43.jpg'
import img44 from '../../assets/img/img44.jpg'
import img59 from '../../assets/img/img59.jpg'
import img61 from '../../assets/img/img61.jpg'
import img64 from '../../assets/img/img64.jpg'
import img62 from '../../assets/img/img62.jpg'
import img50 from '../../assets/img/img50.jpg'
import img54 from '../../assets/img/img54.jpg'
import img55 from '../../assets/img/img55.jpg'

export default function (){

    const items = [
        "Colocar os óculos sobre a face, deixando confortável. Os óculos devem ser de uso exclusivo para cada profissional responsável pela assistência.",
        "O Protetor Facial (Face Shield) deverá ser utilizado para os atendimentos e transportes de pacientes com sintomas respiratórios, suspeitos ou confirmados com Sars-CoV-2. ",
        "O protetor facial deve ser de uso individual e cada profissional deve efetuar a higiene do seu equipamento após cada atendimento ou transporte.  ",
        "Os protetores faciais devem ser utilizados sobre as máscaras (máscara de proteção respiratória tipo N95, N99, N100, PFF2 ou PFF3) e sobre os óculos de proteção. ",
    ]

    return (
        <Container>
            <Title>Técnica de colocação de óculos protetores e Face Shield</Title>
            
            <List items={items} />

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/2BwDchQfltQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img53} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img43} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img44} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img59} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img61} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img64} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img62} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img64} />
            <img className="img-fluid mb-3 col-6" alt="óculos" src={img50} />
            <img className="img-fluid mb-3 col-6" alt="óculos" src={img54} />
            <img className="img-fluid mb-3 col-6" alt="óculos" src={img55} />

            <BackButton/>
        </Container>
    )
}