import styled from 'styled-components'

const Container = styled.div``

const InlineImages = styled.div`

`

export {
    Container,
    InlineImages
}