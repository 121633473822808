import React from 'react'
import {
    Title,
    BackButton,
    List,
    SubTitle
} from '../../components'
import { Container } from './styles'

import img93 from '../../assets/img/img93.jpg'
import img94 from '../../assets/img/img94.jpg'
import img95 from '../../assets/img/img95.jpg'
import img96 from '../../assets/img/img96.jpg'


export default function (){

    const items = [
        "Durante a retirada das luvas, o profissional deve evitar tocar o lado externo, pois estará contaminado.",
        "Com as duas mãos enluvadas, segure a parte externa de uma luva na parte superior do pulso. ",
        "Retire esta primeira luva, afastando-se do corpo e do pulso até as pontas dos dedos, virando a luva de dentro para fora. ",
        "Segure a luva que você acabou de remover em sua mão enluvada. ",
        "Com uma mão sem luva, retire a segunda luva inserindo os dedos dentro da luva na parte superior do pulso.",
        "Vire a segunda luva do avesso enquanto a inclina para longe do corpo, deixando a primeira luva dentro da segunda. ",
        "Descarte as luvas na lixeira. Não reutilize as luvas. ",
        "Lave as mãos com água e sabão ou higienize com solução alcoólica a 70%.",
    ]

    return (
        <Container>
            <Title>Técnica da retirada da luva </Title>
            
            <List items={items} />

            <SubTitle>Técnica de retirada da luva (uso de uma luva)</SubTitle>

            <img className="img-fluid mb-3 col-6" alt="Retirada Touca" src={img93} />
            <img className="img-fluid mb-3 col-6" alt="Retirada Touca" src={img94} />
            <img className="img-fluid mb-3 col-6" alt="Retirada Touca" src={img95} />
            <img className="img-fluid mb-3 col-6" alt="Retirada Touca" src={img96} />

            <SubTitle>Técnica de retirada da luva (uso de duas luvas)</SubTitle>

            

            <BackButton/>
        </Container>
    )
}