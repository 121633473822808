import React from 'react'

import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { 
    Container
} from './styles'

import rodape from '../../assets/RODAPE.png'

export default function(){
    const history = useHistory()
    return (
        <Container>
            <Button
                color="warning"
                outlined="outlined"
                onClick={() => history.goBack()}
            >Voltar</Button>
            <img className="img-fluid" src={rodape} />
        </Container>
    )
}