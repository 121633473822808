import React from 'react'
import {
    Title,
    BackButton,
    List
} from '../../components'
import { Container } from './styles'

import img89 from '../../assets/img/img89.jpg'
import img90 from '../../assets/img/img90.jpg'
import img91 from '../../assets/img/img91.jpg'
import img92 from '../../assets/img/img92.jpg'

export default function (){

    const items = [
        "Calce as luvas e estenda-as até cobrir o punho do avental de isolamento. ",
        "Troque as luvas sempre que for necessário ou quando for entrar em contato com outro paciente. ",
        "Troque as luvas durante o contato com o paciente se for mudar de um sítio corporal contaminado para outro limpo ou quando essa estiver danificada.",
        "Nunca toque desnecessariamente em superfícies e materiais (tais como telefones, maçanetas, portas), quando estiver com luvas. ",
        "Não lave ou use novamente o mesmo par de luvas. As luvas não devem ser reutilizadas. ",
        "Proceda à higiene das mãos imediatamente após a retirada das luvas.",
    ]

    return (
        <Container>
            <Title>Técnica de colocação da Luva</Title>
            
            <List items={items} />

            <img className="img-fluid mb-3 col-6" alt="Uso de Touca" src={img89} />
            <img className="img-fluid mb-3 col-6" alt="Uso de Touca" src={img90} />
            <img className="img-fluid mb-3 col-6" alt="Uso de Touca" src={img91} />
            <img className="img-fluid mb-3 col-6" alt="Uso de Touca" src={img92} />

            <BackButton/>
        </Container>
    )
}