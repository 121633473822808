import React from 'react'
import Header from '../Header'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import {
  Background
} from './styles'

import {
  Home, 
  Introducao, 
  Sumario,
  Pandemia,
  Epi,
  Mascara,
  MascaraCirurgica,
  ColocacaoMascara,
  RetiradaMascara,
  MascaraRespiratoria,
  ColocacaoMascaraRespiratoria,
  RetiradaMascaraRespiratoria,
  Touca,
  UsoTouca,
  RetiradaTouca,
  Oculos,
  ColocacaoOculos,
  RetiradaOculos,
  ColocacaoAvental,
  RetiradaAvental,
  Avental,
  Luva,
  ColocacaoLuva,
  RetiradaLuva,
  RecomendacoesEPI,
  AlgoritmosEPI,
  Lesoes,
  Referencias
} from '../../pages'

export default function (){
  return (
    <Background>
      <Header/>
      <div className="container-xl">
        <Router>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>

            <Route path="/sumario" exact>
              <Sumario />
            </Route>

            <Route path="/introducao" exact>
              <Introducao />
            </Route>

            <Route path="/pandemia" exact>
              <Pandemia />
            </Route>

            <Route path="/epi" exact>
              <Epi />
            </Route>

            <Route path="/mascara" exact>
              <Mascara />
            </Route>

            <Route path="/mascaraCirurgica" exact>
              <MascaraCirurgica />
            </Route>

            <Route path="/colocacaoMascaraCirurgica" exact>
              <ColocacaoMascara />
            </Route>

            <Route path="/retiradaMascaraCirurgica" exact>
              <RetiradaMascara />
            </Route>

            <Route path="/mascarasRespiratoria" exact>
              <MascaraRespiratoria />
            </Route>

            <Route path="/colocacaoMascaraRespiratoria" exact>
              <ColocacaoMascaraRespiratoria />
            </Route>

            <Route path="/RetiradaMascaraRespiratoria" exact>
              <RetiradaMascaraRespiratoria />
            </Route>

            <Route path="/touca" exact>
              <Touca />
            </Route>

            <Route path="/usoTouca" exact>
              <UsoTouca />
            </Route>

            <Route path="/retiradaTouca" exact>
              <RetiradaTouca />
            </Route>

            <Route path="/oculos" exact>
              <Oculos />
            </Route>

            <Route path="/colocacaoOculos" exact>
              <ColocacaoOculos />
            </Route>

            <Route path="/retiradaOculos" exact>
              <RetiradaOculos />
            </Route>

            <Route path="/avental" exact>
              <Avental />
            </Route>

            <Route path="/colocacaoAvental" exact>
              <ColocacaoAvental />
            </Route>

            <Route path="/retiradaAvental" exact>
              <RetiradaAvental />
            </Route>

            <Route path="/luva" exact>
              <Luva />
            </Route>

            <Route path="/colocacaoLuva" exact>
              <ColocacaoLuva />
            </Route>

            <Route path="/retiradaLuva" exact>
              <RetiradaLuva />
            </Route>

            <Route path="/recomendacoesEPI" exact>
              <RecomendacoesEPI />
            </Route>

            <Route path="/algoritmosEPI" exact>
              <AlgoritmosEPI />
            </Route>

            <Route path="/lesoes" exact>
              <Lesoes />
            </Route>

            <Route path="/referencias" exact>
              <Referencias />
            </Route>
          </Switch>
        </Router>
      </div>
    </Background>
  )
}
