import React from 'react'
import {
    Title,
    BackButton,
    List
} from '../../components'
import { Container } from './styles'

import img63 from '../../assets/img/img63.jpg'
import img62 from '../../assets/img/img62.jpg'
import img44 from '../../assets/img/img44.jpg'
import img55 from '../../assets/img/img55.jpg'
import img54 from '../../assets/img/img54.jpg'
import img50 from '../../assets/img/img50.jpg'



export default function (){

    const items = [
        "Remova o protetor facial pela lateral ou pelas hastes, considerando que a parte frontal está contaminada. ",
        "Após o seu uso, o profissional deverá fazer a assepsia, (caso não possa ser descartado) de acordo com protocolo da CCIH do serviço de saúde. ",
    ]

    return (
        <Container>
            <Title>Técnica de retirada do protetor facial (Face Shield)</Title>
            
            <List items={items} />

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/eToRnrQc9Ow" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img63} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img62} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img44} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img55} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img54} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img50} />


            <BackButton/>
        </Container>
    )
}