import React from 'react'
import {
    Title,
    Paragraph,
    BackButton
} from '../../components'
import { Container } from './styles'

import img7 from '../../assets/img/img7.jpg'

export default function (){

    return (
        <Container>
            <Title>Máscara Cirúrgica</Title>
            
            <Paragraph>
                O uso da máscara cirúrgica para pacientes e profissionais de saúde reduz a transmissão de gotículas, quando nos casos de contato com pessoas suspeitas de SARS- CoV-2 e com sintomas respiratórios brandos, desde a chegada ao serviço de saúde, ao local de isolamento e durante a circulação dentro do serviço (transporte de uma área/setor para outro), devendo-se evitar ao máximo tocar a máscara, olhos, boca e face. (ANVISA, 2020; HELLEWELL et al., 2020; PACZEK et al., 2020).
            </Paragraph>

            <img className="img-fluid" alt="Máscara cirúrgica" src={img7} />

            <BackButton/>
        </Container>
    )
}