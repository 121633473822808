import React from 'react'
import Title from '../../components/Title'
import { Container } from './styles'
import { useHistory } from 'react-router-dom'
import  Button  from '../../components/Button'

export default function (){
    const opcoes = [
        {
            title: 'Introdução',
            url: '/introducao'
        },
        {
            title: 'Pandemia pela infecção pelo Sars-Cov-2',
            url: '/pandemia'
        },
        {
            title: 'Use de equipamento de proteção individual(EPI) pelos profissionais de saúde',
            url: '/epi'
        },
        {
            title: 'Lesões por dispositivos médicos durante a pademia pelo Sars-Cov-2',
            url: '/lesoes'
        },
        {
            title: 'Referências',
            url: '/referencias'
        }
    ]

    const history = useHistory()

    return (
        <Container>
            <Title>Sumário</Title>
            {
                opcoes.map((opcao, index) => (
                    <Button
                        outlined="outlined"
                        color="success"
                        onClick={() => history.push(opcao.url)}
                        key={index}
                    >{ opcao.title }</Button>
                ))
            }
        </Container>
    )
}