import React from 'react'
import {
    Title,
    BackButton,
    List
} from '../../components'
import { Container } from './styles'

import img41 from '../../assets/img/img41.jpg'
import img39 from '../../assets/img/img39.jpg'
import img40 from '../../assets/img/img40.jpg'


export default function (){

    const items = [
        "A touca deve ser retirada após o avental ou capote.",
        "Para retirar a touca/gorro, puxe pela parte superior central sem tocar nos cabelos. ",
        "Descarte a touca/gorro em recipiente apropriado. ",
        "Lave as mãos com água e sabão ou higienize com solução alcoólica a 70%.",
    ]

    return (
        <Container>
            <Title>Técnica da retirada da touca </Title>
            
            <List items={items} />

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/Y5w_AfkIh84" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <img className="img-fluid mb-3 col-6" alt="Retirada Touca" src={img41} />
            <img className="img-fluid mb-3 col-6" alt="Retirada Touca" src={img39} />
            <img className="img-fluid mb-3 col-6" alt="Retirada Touca" src={img40} />


            <BackButton/>
        </Container>
    )
}