import React from 'react'
import {
    Title,
    BackButton,
    List
} from '../../components'
import { Container } from './styles'

import img11 from '../../assets/img/img11.jpg'
import img12 from '../../assets/img/img12.jpg'
import img13 from '../../assets/img/img13.jpg'

export default function (){

    const items = [
        "Verifique se a máscara não está danificada.",
        "Utilize o clip nasal como referência para identificar a parte superior.",
        "Coloque a máscara em seu rosto e prenda as alças atrás da cabeça, mantendo-as paralelas (nunca cruzadas).",
        "Aperte o clip nasal ou a borda rígida da máscara para que ela se adapte ao formato do seu nariz, visando minimizar espaços entre a face e a máscara.",
        "Puxe a parte inferior da máscara para que ela cubra sua boca e seu queixo."
    ]

    return (
        <Container>
            <Title>Máscara Cirúrgica</Title>
            
            <List items={items} />

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/LBq66_AF1hc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <img className="img-fluid mb-3" alt="Máscara cirúrgica" src={img11} />
            <img className="img-fluid mb-3" alt="Máscara cirúrgica" src={img12} />
            <img className="img-fluid mb-3" alt="Máscara cirúrgica" src={img13} />

            <BackButton/>
        </Container>
    )
}