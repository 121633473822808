import React from 'react'
import Title from '../../components/Title'
import { Container } from './styles'
import  {
    BackButton,
    List,
}  from '../../components'

export default function (){

    const references = [
        "Almeida Ildeberto Muniz de. Proteção da saúde em tempos de COVID-19 e respostas à pandemia. Rev. bras. saúde ocup.[Internet]. 2020 [cited 2020 July 06]: 45: e17.Available from: http://www.scielo.br/scielo.php?script=sci_arttext&pid=S0303-76572020000101500&lng=enEpubJune10,2020.https://doi.org/10.1590/scielopreprints.140.",
        "Houghton C, Meskell P, Delaney H, Smalle M, Glenton C, Cabine A, Chan XHS, Devane D, Biesty LM. Barreiras e facilitadores à adesão dos profissionais de saúde às diretrizes de prevenção e controle de infecções (IPC) para doenças infecciosas respiratórias: uma rápida síntese qualitativa de evidências. Banco de Dados Cochrane de Revisões Sistemáticas 2020,Edição 4. Art. No .: CD013582. DOI: 10.1002 / 14651858.CD013582.",
        "Luong Thanh BY, Laopaiboon M, Koh D, Sakunkoo P, Moe H. Behavioural interventions to promote workers’ use of respiratoryprotective equipment. Cochrane Database of Systematic Reviews 2016, Issue 12. Art. No.: CD010157. DOI: 10.1002/14651858. CD010157.pub2.",
        "Ramalho AO; Freitas PSS; Nogueira PC. Lesão por pressão relacionada a dispositivo médico nos profissionais de saúde em época de pandemia. Estima, Braz. J. Enterostomal Ther., 18: e0120; 2020. ",
        "Berke C, Bryant D, Kent D, Pontieri-Lewis V. Guidance for Maintaining Skin Health When Utilizing Protective Masks for Prolonged Time Intervals. J Wound Ostomy Continence Nurs. 2020;47(4):317-318",
        "Smart H, Opinion FB,  Darwich I, Elnawasany MA, Kodange C.Preventing Facial Pressure Injury for Health Care Providers Adhering to COVID-19 Personal Protective Equipment Requirements.Adv Skin Wound Care 2020;33:1–9.DOI: 10.1097/01.ASW.0000669920.94084.c1",
        "Agência Nacional de Vigilância Sanitária (ANVISA). Nota Técnica GVIMS/GGTES/ANVISA Nº 04/2020: Orientações para serviços de saúde: medidas de prevenção e controle que devem ser adotadas durante a assistência aos casos suspeitos ou confirmados de infecção pelo novo coronavírus (SARS-CoV-2): atualizada em 08/05/2020. Brasília: ANVISA; 2020. [acesso 2020 Mar 23]. Avaiable from http://portal.anvisa.gov.br/documents/33852/271858/Nota+T%C3%A9cnica+n+04-2020+GVIMS-GGTES-ANVISA/ab598660-3de4-4f14-8e6f-b9341c196b28.",
        "Ansell Care Education. Evidence. Engagement. A self study guide. Respiratory protection in surgery [Internet]. Iselin (NJ): Ansell Healthcare Products LLC; 2017 Available from: http://www.ansellhealthcare.com/pdf/ceu/respiratory_protection.pdf.",
        "Belasco AGS, Fonseca CD. Coronavírus 2020. Rev Bras Enferm. 2020;73(2):e2020n2. Doi: http://dx.doi.org/10.1590/0034-7167-2020730201.",
        "Carvalho JFS, Chaves LDP. Supervisão de enfermagem no uso de equipamento de proteção individual em um hospital geral. Cogitare Enferm. 2010;15(3):513-20. Doi: http://dx.doi.org/10.5380/ce.v15i3.18897 ",
        "Chaves TSS, Bellei N. SARS-COV-2, o novo Coronavírus: uma reflexão sobre a Saúde Única (One Health) e a importância da medicina de viagem na emergência de novos patógenos.  Rev Med. 2020;99(1):i-iv. Doi: http://dx.doi.org/10.11606/issn.1679-9836.v99i1pi-iv",
        "Chen J. Pathogenicity and transmissibility of 2019-nCoV: a quick overview and comparison with other emerging viruses. Microbes and Infection. 2020;22(2):69-71. Doi: https://doi.org/10.1016/j.micinf.2020.01.004.)",
        "Conselho Federal de Enfermagem (COFEN). Saúde de profissionais de enfermagem é foco em tempos de Covid-19 [Internet]. Brasília: COFEN; 2020. Avaiable from: http://www.cofen.gov.br/saude-de-profissionais-de-enfermagem-e-foco-em-tempos-de-covid-19_78321.html.",
        "Conselho Federal de Enfermagem (COFEN). Covid – 19 Orientações sobre a Colocação e Retirada dos Equipamentos deProteção Individual (EPIs). Brasília. 2020. 18pg.",
        "Cordeiro JFC, Alves AP, Gir E, Miranda DO, Canini SRMS. Uso de equipamento de proteção individual em um serviço de atenção domiciliar. Cogitare Enferm. 2016;21(3): 1-8. DOI: http://dx.doi.org/10.5380/ce.v21i3.45443.",
        "Fencl JL. Guideline for surgical smoke safety. AORN J. 2017 May;105(5):488-49. Doi: 10.1016/j.aorn.2017.03.006.",
        "Ferioli M, Cisternino C, Leo V, Pisani L, Palange P, Nava S. Protecting healthcare workers from SARS-CoV-2 infection: practical indications. Eur Respir Rev. 2020;29(155):200068. Doi: 10.1183/16000617.0068-2020. ",
        "Freitas MGG. Orientação nº 002/2020 de 25/01/2020 atualizada a 10/02/2020: infecção pelo novo Coronavírus (2019-nCoV). Lisboa: Serviço Nacional de Saúde; 2020. Disponível em: https://covid19.min-saude.pt/wp-content/uploads/2020/03/Orientac%CC%A7a%CC%83o-002-1.pdf.",
        "Gefen A, Ousey K.Update to device-related pressure ulcers: SECURE prevention. COVID-19, face masks and skin damage. J Wound Care. 2020;29(5):245-59. Doi: 10.12968/jowc.2020.29.5.245.",
        "de Almeida IM. Proteção da saúde dos trabalhadores da saúde em tempos de COVID-19 e respostas à pandemia. Rev Bras Saude Ocup 2020;45:e17. https://doi.org/10.1590/SciELOPreprints.140",
        "Silva LS, Machado EL, Oliveira HN, Ribeiro AP. Condições de trabalho e falta de informações sobre o impacto da COVID-19 entre trabalhadores da saúde. Rev Bras Saude Ocup 2020;45:e24.https://doi.org/10.1590/2317-6369000014520  ",
        "Fiho JMJ, Assunção AÁ, Algrantic E, Garcia EG, Saito CA,  Maeno M. A saúde do trabalhador e o enfrentamento da COVID-19. Rev Bras Saude Ocup 2020;45:e14. https://doi.org/10.1590/2317-6369ED0000120",
        "Oliveira HC, Souza LC, Leite TC, Campos JF. Personal Protective Equipment in the coronavirus pandemic: training with Rapid Cycle Deliberate Practice. Rev Bras Enferm. 2020;73(Suppl 2):e20200303. doi: http://dx.doi.org/10.1590/0034-7167-2020-0303",
        "Gefen A, Alves P, Ciprnadi G, Coyer F, Milne C, Ousey K, Ohura K, Waters N, Worley P, Black J, Barakt-Johnson M, Beeckman D, Fletcher J, Kirkland-Kyhn H, Lahmann NA, Moore Z, Payan Y, Schlüer A-B. An international consensus on pressure ulcers related to safe prevention devices. Br J Nurs. 2020;29(5):S36-S8. Doi: 10.12968/bjon.2020.29.5.S36.",
        "Soares SSS, Souza NVDO, Silva KG, Cesar MP, Souto JSS, Leite JCRAP. Covid-19 pandemic and rational use of personal protective equipment. Rev enferm UERJ, Rio de Janeiro, 2020; 28:e50360 .DOI: http://dx.doi.org/10.12957/reuerj.2020.50360",
        "Guan W, Ni Z, Hu Y, Ou LC, He J, Liu L, Sjan H, Lei C, Hui DSC, Du B, Li l, Zeng G, Yuen K-Y, Chen R, Tang PC, Xiang S, Wang J-L, Liang Z, Peng Y, Wei L, Liu Y, Hu Y-h, Peng P, Wang J-m, Liu J, Chen Z, Li G, Zheng Z, Qiu S, Luo J, Ye C, Zhu S, Zhong N for the China Medical treatment Expert Group for Covid-19. Clinical characteristics of Coronavirus disease 2019 in China. N Engl J Med. 2020;382(18): 1708-20. Doi 10.1056/NEJMoa2002032.",
        "Mills J. Medics left with sore marks all over their skin from coronavirus face masks. Londres: Metro; 2020. [citado em 21 mar 2020].Disponível em: https://metro.co.uk/2020/02/05/medics-left-sore-marks-skin-coronavirus-face-masks-12188952/",
        "Hellewell J, Abbott S, Gimma A, Bosse NI, Jarvis CI, Russell TW, Mundasy JD, Munday JD, Kucharski AJ, Edmunds J, Sun F, Flasche S, Quilty BJ, Davies N, Liu Y, Cliford S, Klepac P, Jil M, Diamond C, Gibbs H, van Zandvoort K. Feasibility of controlling COVID-19 outbreaks by isolation of cases and contacts. The Lancet Global Health. 2020;8(4). Doi: https://doi.org/10.1016/S2214-109X(20)30074-7.",
        "Kampf G, Todt T, Pfaender S, Steinmann E. Persistence of coronaviruses on inanimate surfaces and their inactivation with biocidal agents. J Hosp Infect. 2020;104(3):246-51. Disponível em: https://dx.doi.org/10.1016/j.jhin.2020.01.022.",
        "Lima KC, Nunes VMAS, Rocha NSDP, Rocha PM, Andrade I, Uchoa SAC, Cortez LR. A pessoa idosa domiciliada sob distanciamento social: possibilidades de enfrentamento à Covid-19. Rev Bras Geriatr Gerontol. 2020;23(2):e200092. Doi: http://dx.doi.org/10.1590/1981-22562020023.200092.",
        "Mao L, Jin H, Wang M, HU Y, Chen S, He Q, Chang J, Hong C, Zhou DS, Miao X, Li Y, Hu B. Neurologic manifestations of hospitalized patients with Coronavirus Disease 2019 in Wuhan, China. JAMA Neurol. 2020;77(6):683-690. Doi:10.1001/jamaneurol.2020.1127.",
        "Miranda FMA, Santana L de L, Pizzolato AC, Saquis LMM. Condições de trabalho e o impacto na saúde dos profissionais de enfermagem frente a Covid-19. Cogitare Enferm. 2020;25:e72702. Doi: http://dx.doi.org/10.5380/ce.v25i0.72702.",
        "Moraes JT, Borges EL, Lisboa CR, Cordeiro DCO. Conceito e classificação de lesão por pressão: atualização do National pressure ulcer advisory panel. Enferm Cent O Min. 2016;6(2):2292-306. Doi: 10.19175/recom.v6i2.1423.",
        "Moura A, Vaz A, Ferreira AA, Malcato A, Sousa F, Afonso G, Homem-Silva P, Alves P, Ramos P, Dias V. APTFeridas. Recomendação PREPI/COVID19: prevenção de lesões cutâneas causadas pelos equipamentos de proteção individual (máscaras faciais, respiradores, viseiras e óculos de proteção). [Internet]. Porto: Associação Portuguesa de Tratamento de Feridas; 2020.  Avaiable from: https://www.aptferidas.com/Ficheiros/COVID19/APTFeridas%20-%20RECOMENDAC%CC%A7A%CC%83O%20PREPI-COVID19%20ING.pdf.",
        "Munster VJ, Koopmans M, van Doremalen N, van Riel D, de Wit E. A novel coronavirus emerging in China: key questions for impact assessment. N Engl Med J. 2020;382(8):692-4. Doi: 10.1056/NEJMp2000929.",
        "Neves HCC, Souza ACS, Medeiros M, Munari DB, Ribeiro LCM, Tipple AFV. Segurança dos trabalhadores de enfermagem e fatores determinantes para adesão aos equipamentos de proteção individual.  Rev Latino-Am Enfermagem. 2011;19(2):354-61. Doi:  https://doi.org/10.1590/S0104-11692011000200018. ",
        "Oliveira AC, Lucas TC, Iquiapaza RA. O que a pandemia da Covid-19 tem nos ensinado sobre adoção de medidas de precaução? Texto Contexto Enferm. 2020;29:e20200106. Doi: https://doi.org/10.1590/1980-265X-TCE-2020-0106.",
        "OMS - Organização Pan-Americana da Saúde. Ocorrência de variantes de SARS-CoV-2 nas Américas, 20 de janeiro de 2021. Brasilia, D.F.: Organização Pan-Americana da Saúde; 2021. ",
        "Paczek RS, Espírito Santo DMN, Galvan C. Utilização de equipamentos de proteção individual em unidade endoscópica. Rev Enferm UFPE on line. 2020;14:e243993 Doi: https://doi.org/10.5205/1981-8963.2020.243993.",
        "Paules CI, Marston HD, Fauci A. Coronavirus infections: more than just the common cold. JAMA. 2020;323(8):707-8. Doi: 10.1001/jama.2020.0757.",
        "Rieth GH, Loro MM, Stumm EMF, Rosanelli CLSP, Kolankiewicz ACB, Gomes JS.  Uso de equipamentos de proteção individual pela enfermagem em uma unidade de emergência hospitalar. Rev Enferm UFPE on line. 2014;8(2):365-71. Doi: 10.5205/reuol.4688-38583-1-RV.0802201418.",
        "Santos EI, Gomes AMT, Marques SC.  Acidentes ocupacionais biológicos e práticas protetoras evidenciados nas representações sociais de enfermeiros sobre sua vulnerabilidade. Revista Baiana de Enfermagem. 2015;29(4):391-9. Doi: http://dx.doi.org/10.18471/rbe.v29i4.13802.",
        "Schwartz D, Magen YK, Levy A, Gefen A. Effects of humidity on skin friction against medical textiles as related to prevention of pressure injuries. Int Wound J. 2018; 15(6):866-74. Doi: 10.1111/iwj.12937. ",
        "Sinvamani RK, Goodman J, Gitis N, Maaibach H. Coefficient of friction:tribological studies in man: an overview. Skin Res Technol. 2003;9(3):227-34. Doi: 10.1034/j.1600-0846.2003.02366.x.",
        "Wilson ME, Chen LH. Travelers give wings to novel coronavirus (2019-nCoV). J Travel Med. J Travel Med. 2020;27(2). Doi: https://doi.org/10.1093/jtm/taaa015.",
        "World Health Organization (WHO). Rational use of personal protective equipment (PPE) for coronavirus disease (COVID-19): interim guidance [Internet]. Geneva: WHO; 2020. Avaiable from: https://apps.who.int/iris/handle/10665/331498.",
        "Zumla A, Dar O, Kock R, Muturi M, Ntoumi F, Kaleebu P, Eusebio M, Mfinanga S, Bates M, Mwaba P, Ansumana R, Khan M, Alagaili AN, Cotten M, Azhar EI, Maeurer M, Ippolito G, Petersen E. Taking forward a “One Health” approach for turning the tide against the Middle East respiratory syndrome coronavirus and other zoonotic pathogens with epidemic potential. Int J Infect Dis. 2016;47:5-9. Doi: 10.1016/j.ijid.2016.06.012)",
    ]

    return (
        <Container>
            <Title>Referências</Title>

            <List items={references} />

            <BackButton />
        </Container>
    )
}