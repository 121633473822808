import React from 'react'
import Title from '../../components/Title'
import { Container } from './styles'
import  {
    BackButton,
    Paragraph,
    SubTitle
}  from '../../components'

import img102 from '../../assets/img/img102.png'
import img103 from '../../assets/img/img103.png'
import img104 from '../../assets/img/img104.png'
import img105 from '../../assets/img/img105.png'
import img106 from '../../assets/img/img106.png'
import img107 from '../../assets/img/img107.png'
import algoritmo4 from '../../assets/img/algoritmo4.png'

export default function (){

    return (
        <Container>
            <Title>LESÕES POR DISPOSITIVOS MÉDICOS DURANTE A PANDEMIA PELA COVID 19</Title>

            <Paragraph>
                A pele é a primeira barreira de defesa contra as agressões do meio ambiente e é frequentemente sujeita a forças mecânicas e químicas (SIVAMANI et al., 2003), pelo quê, suas características protetoras ficam alteradas, como é o caso da utilização contínua de dispositivos médicos/EPIs. Isso se deve a fatores físicos, como a pressão sustentada, forças de tensão, fricção e de atrito, bem como a umidade e a temperatura, que estão diretamente associados ao desenvolvimento de Úlceras por Pressão (UP), lesões por fricção e quebras cutâneas. (SCHWARTZ et al., 2018).
            </Paragraph>
            <Paragraph>
                As publicações relacionadas à prevenção e ao tratamento das lesões associadas ao uso desses equipamentos são relacionadas mais aos doentes e não aos profissionais de saúde. Os dispositivos médicos mais referidos na literatura são os tubos traqueais, sondas nasogástricas, cateteres de oxigenioterapia, máscaras de ventilação, sondas vesicais e colares cervicais. Mas, no momento atual, em que há necessidade de utilização rigorosa dos EPIs, por parte dos profissionais da saúde, podemos observar o aumento do aparecimento de lesões por pressão ocasionadas pelo uso dos EPIs por esses trabalhadores. (WHO,2020).
            </Paragraph>
            <Paragraph>
                Estas lesões podem elevar o risco de alterações estéticas, dermatoses, úlceras cutâneas e infecções. Podem causar dor, cicatrizes, resultar em perda de cabelos, alteração na imagem corporal e ou qualidade de vida reduzida, bem como aumentar o tempo de internação e incremento nas despesas médicas, com repercussões no psiquismo do profissional lesado. (CHAVES & BELEI, 2020). 
            </Paragraph>
            <Paragraph>
                Os EPIs devem estar de acordo com o nível de cuidados prestados pelo profissional de saúde, alinhados com as recomendações da ANVISA. (BELASCO & FONSECA, 2020; CHAVES & BELEI, 2020).
            </Paragraph>
	        <Paragraph>
                Contudo o uso prolongado e contínuo das máscaras faciais, dos óculos e face shield é responsável pelas forças de fricção e pressão constantes nos tecidos, causando lesões cutâneas. (JWOCN-2020).
            </Paragraph>
	        <Paragraph>
                Dados epidemiológicos revelam que as lesões associadas ao uso de máscara N-95 mais frequentes relatadas pelos profissionais são acne (59,6%), prurido facial (51,4%) e rash cutâneo (35,8%). (MUNSTER et al., 2020).
            </Paragraph>
            <Paragraph>
                Se conseguirmos reduzir estas forças físicas sem alterar a capacidade de segurança dos EPIs, poderemos minimizar o impacto nos tecidos e melhorar a capacidade de resposta da pele às constantes agressões.  
            </Paragraph>
            <Paragraph>
                No mês de abril de 2016, o National Pressure Ulcer Advisory Panel anunciou uma mudança na terminologia de úlcera de pressão para lesão por pressão, e também incluiu lesão por pressão relacionada a Dispositivo Médico. Esta lesão resulta do uso de dispositivos concebidos e aplicados para fins de diagnóstico ou terapêutico, e caracteriza-se quando o profissional utiliza os EPIs e contrai lesão durante o uso, conforme ilustrações na figura 23 e reprodução de fotos na figura 24 e 25 a seguir. (RIETH et al., 2014; MORAES et al., 2016; ANVISA, 2020; COFEN, 2020; GEFEN & OUSEY, 2020; MOURA et al., 2020; GREFEN et al., 2020).
            </Paragraph>

            <img className="img-fluid mt-3 mb-3" src={img102} alt="Lesões" />

            <Paragraph>
                A formação de LPP em região facial dos profissionais da saúde geralmente é ocasionada pelo uso de dispositivos médicos.  Os EPIs, como máscaras, óculos e as luvas, além das lesões, também podem causar urticária, irritação da pele, ressecamento da pele e dermatite de contato, sendo então denominadas de Lesões por Pressão Relacionadas a Dispositivos Médicos.
            </Paragraph>

            <img className="img-fluid mt-3 mb-3" src={img103} alt="Lesões" />

            <Paragraph>
                O uso prolongado ou incorreto das máscaras faciais, óculos, de Protetor Facial é o responsável pelas forças de fricção e pressão constantes nos tecidos, levando os profissionais a sofrerem as lesões. (RIETH et al., 2014; MORAES et al., 2016; COFEN, 2020; GEFEN & OUSEY, 2020).
            </Paragraph>
            <Paragraph>
                Os cuidados para a prevenção da lesão por pressão relacionada aos dispositivos médicos causada pelo uso do EPIs devem ser realizados por meio do exame diário da pele, reposicionamento dos dispositivos de modo a reduzir a força física (fricção, cisalhamento), sem alterar a capacidade de segurança dos EPIs. Pode-se assim reduzir o impacto nos tecidos e melhorar a capacidade de resposta da pele às constantes agressões. (RIETH et al., 2014; MORAES et al., 2016; ANVISA, 2020; COFEN, 2020; GEFEN & OUSEY, 2020; MOURA et al., 2020).
            </Paragraph>
            <Paragraph>
                Sabemos que a fricção da pele é determinada pelas propriedades da sua superfície (oleosidade, rugosidade, estado de hidratação, entre outras), pelas propriedades dos materiais em contato (rígidos, macios, fibrosos, duro etc.), bem como pela influência de possíveis camadas intermediárias (cremes, loções, pastas, entre outros), combinadas com o suor e sebo, que são naturalmente excretados em nível cutâneo.
            </Paragraph>
            <Paragraph>
                Nesse sentido, estudo realizado pela APTFeridas (MOURA et al., 2020) sintetizou as medidas preventivas para a lesão por pressão por dispositivo médico causada pelo uso do EPIs, as quais estão descritas a seguir: 
            </Paragraph>
            <Paragraph>
                1 – Uso de cremes e protetores-barreira e os oclusivos. Eles desaceleram a transpiração e reduzem a fricção sobre a pele do profissional, pois, com o tempo, a pele produz oleosidade. Existe também evidência científica que documenta a eficácia dos ácidos gordos hiperoxigenados na prevenção de lesão por pressão por dispositivo médico ocasionada pelo uso do EPIs. 
            </Paragraph>
            <Paragraph>
                2 - A utilização de cobertura (espuma de silicone, poliuretano transparente e hidrocoloide) de prevenção de lesão por pressão decorrente de dispositivo médico por uso do EPIs, frequente na prática clínica, deve ser de nível de evidência B. O material de interface deve ser fino, não promover traumas durante a remoção, absorver a umidade, ser adaptável ao contorno das estruturas da face, garantir a correta selagem da máscara. A figura 26, a seguir, apresenta os tipos de materiais utilizados em tratamentos de lesões por EPIs. 
            </Paragraph>

            <img className="img-fluid mt-3 mb-3" src={img104} alt="Lesões" />

            <Paragraph>
                3 - Ao retirar e fixar a máscara ou o protetor facial, o profissional deve fazê-lo delicadamente, sem imprimir pressão ou força exagerada; tal procedimento tem como finalidade garantir a selagem ideal. O profissional, ao fixar a máscara ou o protetor facial, exercendo força que promova muito pressão, estará provocando fricção na pele, que tem como consequência desconforto e provável lesão. 
            </Paragraph>
            <Paragraph>
                4 - O profissional deve ajustar a máscara ou protetor facial no formato do nariz/face antes de aplicar definitivamente os EPIs.
            </Paragraph>
            <Paragraph>
                5 – Precisa avaliar todas as zonas de contato entre os EPIs e a pele. O nariz, as bochechas, a testa e a região posterior da orelha são zonas de pressão/fricção.
            </Paragraph>
            <Paragraph>
                6 - A hidratação principal da pele não é por via tópica, mas sim por via sistêmica. Os profissionais de saúde devem otimizar a sua hidratação e alimentação para que mantenham uma resposta fisiológica equilibrada.
            </Paragraph>
            <Paragraph>
                7 - Se o material (máscara ou protetor facial) ou EPIs forem molhados ou danificados, deverão ser trocados de imediato.
            </Paragraph>
            <Paragraph>
                8 - Utilizar solução salina fisiológica ou água e sabão neutro para lavar a pele, com particular atenção às áreas que estiveram sob pressão, removendo restos de gordura e descamação que poderão potencializar a maceração.
            </Paragraph>
            <Paragraph>
                9 - Secar bem a face e depois aplicar umectantes na pele (creme hidratante, ácidos gordos hiperoxigenados, material de penso). 
            </Paragraph>
            <Paragraph>
                10 - Os cuidados com a pele, com aplicações diárias de hidratação e proteção, irão reforçar as zonas que estiveram em risco e dar maior proteção para os próximos turnos, em que as tensões serão mantidas nos mesmos locais. 
            </Paragraph>

            <img className="img-fluid mt-3 mb-3" src={img105} alt="Lesões" />
            <img className="img-fluid mt-3 mb-3" src={img106} alt="Lesões" />
            <img className="img-fluid mt-3 mb-3" src={img107} alt="Lesões" />

            <SubTitle>Algoritmo para prevenir lesão causada pelo uso dos equipamentos de proteção individual  na pandemia por SARS-CoV-2.</SubTitle>

            <img className="img-fluid mt-3 mb-3" src={algoritmo4} alt="Lesões" />


            <BackButton />
        </Container>
    )
}