import React from 'react'
import {
    Title,
    Paragraph,
    BackButton
} from '../../components'
import { Container } from './styles'

import img11 from '../../assets/img/img11.jpg'
import img101 from '../../assets/img/img101.png'

export default function (){

    return (
        <Container>
            <Title>Máscara Cirúrgica</Title>
            
            <Paragraph>
                Durante a retirada da máscara, o profissional deve evitar tocar a parte frontal, pois ela poderá estar contaminada. Segure as alças inferiores e depois as alças ou elástico superiores e remova-a, do modo como é ilustrado na figura 10 logo adiante. Descarte a máscara em uma lixeira.
            </Paragraph>

            <Paragraph>
                Lave as mãos com água e sabão ou higienize com solução alcoólica a 70%.
            </Paragraph>

            <Paragraph>
                Observação: não reutilize máscaras descartáveis. Enquanto o profissional estiver usando a máscara, deve evitar tocá-la na parte da frente.
            </Paragraph>

            <Paragraph>
            O profissional deve trocar a máscara quando ela estiver úmida ou sempre que for necessário. Seguem imagens ilustrativas na figura 10 a seguir.
            </Paragraph>

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/q8f_Dg1kj20" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <img className="img-fluid mb-3" alt="Máscara cirúrgica" src={img11} />

            <Paragraph>
                Guardar a máscara em recipiente de papel como se vê na figura 11 seguinte:
            </Paragraph>

            <img className="img-fluid mb-3" alt="Máscara cirúrgica" src={img101} />

            <Paragraph>
                As máscaras cirúrgicas não devem ser sobrepostas às máscaras tipo N95, N99, N100, PFF2 ou PFF3, por não garantirem proteção de filtração ou de contaminação. A máscara N95 ou equivalente poderá ser reutilizada pelo mesmo profissional, desde que cumpridos os passos obrigatórios para sua retirada sem a contaminação do seu interior (ANSELL, 2017; FENCL, 2017; OLIVEIRA et al., 2020).
            </Paragraph>

            <BackButton/>
        </Container>
    )
}