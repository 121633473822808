import React from 'react'
import Title from '../../components/Title'
import { Container } from './styles'
import  {
    BackButton,
    SubTitle
}  from '../../components'

export default function (){

    const items = [
        {
            title: "Ambiente com paciente com a COVID-19",
            items: [
                {
                    publicoAlvo: "Trabalhadores de Assistência à Saúde do doente",
                    atividade: "Propiciando cuidado direto aos doentes ",
                    equipamento: "Máscara FFP2 Luvas duplas não estéreis Óculos ou protetor facial transparente de plástico ou equivalente"
                },
                {
                    publicoAlvo: "Trabalhadores de Assistência à Saúde do doente",
                    atividade: "Aerossóis gerados por procedimento realizado em doentes com a COVID-19 ",
                    equipamento: "Máscara FFP3 Luvas duplas não estéreis Roupa/avental longa resistente à água Óculos ou protetor facial transparente de plástico ou equivalente"
                },
                {
                    publicoAlvo: "Pessoal de limpeza ",
                    atividade: "Ao entrar na sala ou quarto com doente com a COVID-19",
                    equipamento: "Máscara FFP2 Luvas grossas impermeáveis Gorro Óculos ou protetor facial transparente de plástico ou equivalente Botas especiais ou sapatos de trabalho fechados"
                },
                {
                    publicoAlvo: "Visitantes",
                    atividade: "Probidada a visita",
                    equipamento: ""
                }
            ]
        },
        {
            title: "Ambulância ou veículo de transporte de doente com a COVID-19",
            items: [
                {
                    publicoAlvo: "Trabalhadores da área da saúde",
                    atividade: "Transporte de pacientes suspeitos de infecção ou COVID-19",
                    equipamento: "Máscara FFP2 Luvas duplas não estéreis Avental resistente à água Óculos de proteção ou protetor facial acrílico ou plástico transparente"
                }
            ]
        },
        {
            title: "Atendimento ambulatorial quando necessário",
            items: [
                {
                    publicoAlvo: "Trabalhadores da área da saúde ",
                    atividade: "Pacientes com sintomas respiratórios ",
                    equipamento: "Avental descartável Proteção facial com plástico ou acrílico transparente"
                },
                {
                    publicoAlvo: "Trabalhadores da área da saúde",
                    atividade: "Pacientes sem sintomas respiratórios",
                    equipamento: "Não estão indicadas medidas"
                },
                {
                    publicoAlvo: "Pessoas envolvidas com a limpeza do ambiente",
                    atividade: "Limpeza do local antes e depois de cada consulta de paciente com sintoma respiratório",
                    equipamento: "Máscara médica Luvas protetoras para limpeza Gorro Óculos de proteção facial Botas especiais para limpeza ou sapatos fechados"
                }
            ]
        },
        {
            title: "Sala de espera para consultas ",
            items: [
                {
                    publicoAlvo: "Pacientes",
                    atividade: "",
                    equipamento: "Pacientes com sintomas respiratórios devem usar máscara facial médica. Se possível, isolar os pacientes com sintomas respiratórios, além de manter uma distância de 1 metro entre eles."
                }
            ]
        }
    ]

    return (
        <Container>
            <Title>Recomendações sobre o tipo de EPIs indicado para a COVID-19, conforme o tipo de atendimento.</Title>

            {
                items.map((item, index) => (
                    <div className="mt-4 mb-2" key={index}>
                        <SubTitle>{item.title}</SubTitle>
                        {
                            item.items.map((i, index) => (
                                <div className="mt-3 mb-3" key={index}>
                                    <p>
                                        <strong>Público-alvo</strong>: {i.publicoAlvo}
                                    </p>
                                    <p>
                                        <strong>Atividade</strong>: {i.atividade}
                                    </p>
                                    <p>
                                        <strong>Tipo de equipamento individual/procedimento</strong>: {i.equipamento}
                                    </p>
                                    <hr/>
                                </div>
                            ))
                        }
                    </div>
                ))
            }

            <BackButton />
        </Container>
    )
}