import React from 'react'
import Logo from '../Logo'
import { Container } from './styles'

export default function (){
    return (
        <Container className="container-xl">
            <Logo />
        </Container>
    )
}