import React from 'react'

import {
    Title,
    Paragraph, BackButton
} from '../../components'

import {
    Container
} from './styles'

export default function(){
    const paragraphs = [
        "A Pandemia pela Covid-19 trouxe para os dias atuais, diversas preocupações, relacionadas principalmente aos métodos de contenção da disseminação do vírus e da contaminação populacional. Isto ocorre pois até o momento, não existe vacina com eficácia comprovada, sendo fundamental então além das práticas de isolamento social, e dos cuidados de higiene pessoais, a utilização correta dos Equipamentos de Proteção Individual (EPIs).",
        "Dentro das diversas unidades de saúde, hospitais e clínicas, os EPIs são rotineiramente utilizados pelos profissionais da saúde, impedindo ou reduzindo o contato que permitiria a transmissão de microrganismos responsáveis por vários tipos de doenças, contribuindo preventivamente, com a saúde deste trabalhador.",
        "Com a Pandemia houve, em várias áreas dentro dos diversos serviços prestados, aumento da demanda de pacientes, e vários profissionais da saúde que já apresentavam grande carga de trabalho, tiveram que disponibilizar mais tempo, para cuidar dos infectados pela Covid 19. Além disso, grande número de profissionais são contaminados e afastados diariamente, para realizar o isolamento social, exigindo ainda mais esforços dos que permanecem ativos, aumentando o tempo de uso dos EPIs e com isso a ocorrência de lesões teciduais.",
        "O Mestrado Profissional em Ciências aplicadas à Saúde da Universidade do Vale do Sapucaí, Pouso Alegre, MG, que está inserido na área de concentração nomeada por Multi e interdisciplinaridade em lesões teciduais, através do docente permanente Professor Doutor Geraldo Magela Salomé e dos discente Breno Pontes, elaborou este livro que tem por objetivo principal, abordar informações sobre o uso correto dos EPIs, tanto no momento da colocação, quanto na retirada, contribuindo através de informações científico-tecnológicas com a prevenção de Lesões teciduais.",
        "Os autores, inicialmente fazem uma breve descrição sobre o histórico recente da Pandemia pela infecção do vírus SARS-COV19, desde o primeiro caso diagnosticado na cidade Wuhan, província de Hubei, na China, até os casos ocorridos no Brasil, com recomendações da Organização Mundial de Saúde e do próprio Governo do Brasil, através do Ministério da Saúde.",
        "Em seguida, são apresentados os Equipamentos de Proteção Individual disponibilizados aos profissionais da área da saúde, tais como: máscaras e seus tipos, touca, óculos, protetor facial, avental e luvas, assim como todas as técnicas de colocação e retirada destes equipamentos.",
        "Para finalizar, são descritas lesões teciduais por pressão, diagnosticadas em profissionais da área da saúde, que ocorrem pelo uso incorreto ou prolongado, dos EPIs, discutindo através de literatura científica a importância tanto do uso correto dos EPIs, quanto da necessidade do desenvolvimento de novos produtos como cremes e coberturas, que possam contribuir preventivamente, na ocorrência das lesões teciduais.",
    ]
    return (
        <Container>
            <Title>Introdução</Title>

            {
                paragraphs.map(text => (
                    <Paragraph>{ text }</Paragraph>
                ))
            }

            <BackButton />
        </Container>
    )
}