import React from 'react'

export default function ({ items }){
    return (
        <ul>
            {
                items.map((item, index) => (<li key={index} className="m-2 mt-3">{item}</li>))
            }
        </ul>
    )
}