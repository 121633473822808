import styled from 'styled-components'

const Imagem = styled.img`
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    max-width: '100%'
`

export {
    Imagem
}