import styled from 'styled-components'

const Container = styled.h1`
    font-size: 18px;
    text-align: center;
    /* border-bottom: 1px solid #CECECE; */
    color: #2581CE;
`

export {
    Container
}