import styled from 'styled-components'

const Container = styled.div`
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
`

export { 
    Container
}