import React from 'react'
import {
    Title,
    BackButton,
    List,
    Paragraph
} from '../../components'
import { Container } from './styles'

import img28 from '../../assets/img/img28.jpg'
import img29 from '../../assets/img/img29.jpg'
import img30 from '../../assets/img/img30.jpg'
import img31 from '../../assets/img/img31.jpg'
import img33 from '../../assets/img/img33.jpg'
import img36 from '../../assets/img/img36.jpg'


export default function (){

    const items = [
        "O profissional deverá informar-se sobre o protocolo da CCIH do serviço de saúde com relação ao tempo de utilização da máscara.",
        "Segurar o elástico inferior com as duas mãos, passando-o por cima da cabeça para removê-lo. ",
        "Segurar o elástico superior com as duas mãos, passando-o por cima da cabeça para removê-lo.",
        "Remover a máscara, segurando-a pelos elásticos, tomando bastante cuidado para não tocar na superfície interna. ",
        "Acondicionar a máscara em um saco ou envelope de papel com os elásticos para fora para facilitar a retirada posteriormente no caso de reutilização. ",
        "Nunca colocar a máscara já utilizada em um saco plástico, pois ela poderá ficar úmida e potencialmente contaminada. ",
        "Lavar as mãos com água e sabão ou higienizá-las com solução alcoólica a 70%. ",
    ]

    return (
        <Container>
            <Title>Técnica da retirada da máscara de proteção respiratória tipo N95, N99, N100, PFF2 ou PFF3 </Title>
            
            <List items={items} />

            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img28} />
            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img29} />
            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img30} />
            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img31} />

            <Paragraph>Guardar a máscara em um recipiente de papel.</Paragraph>

            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img33} />
            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img36} />


            <BackButton/>
        </Container>
    )
}