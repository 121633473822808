import React from 'react'
import {
    Title,
    Paragraph,
    BackButton
} from '../../components'
import { Container } from './styles'
import { useHistory } from 'react-router-dom'
import  Button  from '../../components/Button'

import img3 from '../../assets/img/img3.jpg'

export default function (){
    const history = useHistory()

    const buttons = [
        {
            title: "Máscara cirúrgica",
            url: "/mascaraCirurgica"
        },
        {
            title: "Técnica de colocação da máscara cirúrgica",
            url: "/colocacaoMascaraCirurgica"
        },
        {
            title: "Técnica de retirada da máscara cirúrgica",
            url: "/retiradaMascaraCirurgica"
        }
    ]

    return (
        <Container>
            <Title>Máscara</Title>
            
            <Paragraph>
                As máscaras funcionam como a combinação básica de EPIs para o profissional da saúde. São fundamentais, pois servem como proteção contra bactérias, fungos e outros agentes contaminantes que possam ser inalados. Seguem, na figura 7, subsequente, foto dos tipos de máscara.
            </Paragraph>

            <img src={img3} className="img-fluid" alt="Mascara" />

            {
                buttons.map((button, index) => (
                    <Button
                        key={index}
                        color="success"
                        outlined="outlined"
                        onClick={() => history.push(button.url)}
                    >
                        {button.title}
                    </Button>
                ))
            }

            <BackButton />
        </Container>
    )
}