import React from 'react'
import {
    Title,
    Paragraph,
    BackButton
} from '../../components'
import { Container } from './styles'
import { useHistory } from 'react-router-dom'
import  Button  from '../../components/Button'

export default function (){
    const history = useHistory()

    const buttons = [
        
        {
            title: "Técnica de colocação da luva",
            url: "/colocacaoLuva"
        },
        {
            title: "Técnica da retirada da luva",
            url: "/retiradaLuva"
        }
    ]

    return (
        <Container>
            <Title>Uso de Luva</Title>
            
            <Paragraph>
                O uso da luva tem como finalidade impedir que o profissional tenha contato direto com agentes físicos e biológicos. 
            </Paragraph>
            <Paragraph>
                Existem três tipos de luvas, sendo que cada um serve para manipular tipos específicos de material:
            </Paragraph>
            <Paragraph>
                <strong>Luvas de látex</strong>: são feitas de material de borracha natural, podendo serem encontradas com ou sem talco.
            </Paragraph>
            <Paragraph>
                <strong>Luvas de nitrílica</strong>: apesar de também serem produzidas com material de borracha, são mais resistentes a óleo, gorduras vegetais e animais, além de água quente por um curto tempo.
            </Paragraph>
            <Paragraph>
                <strong>Luvas de vinil</strong>: compostas por um tipo de plástico com ou sem talco.
            </Paragraph>

            {
                buttons.map((button, index) => (
                    <Button
                        key={index}
                        color="success"
                        outlined="outlined"
                        onClick={() => history.push(button.url)}
                    >
                        {button.title}
                    </Button>
                ))
            }

            <BackButton />
        </Container>
    )
}