import React from 'react'
import {
    Title,
    BackButton,
    List,
    SubTitle
} from '../../components'
import { Container } from './styles'

import img21 from '../../assets/img/img21.jpg'
import img22 from '../../assets/img/img22.jpg'
import img23 from '../../assets/img/img23.jpg'
import img24 from '../../assets/img/img24.jpg'
import img25 from '../../assets/img/img25.jpg'
import img26 from '../../assets/img/img26.jpg'

export default function (){

    const items = [
        "Segurar o respirador com o clip nasal próximo à ponta dos dedos, deixando as alças pendentes.",
        "Encaixar o respirador sob o queixo.",
        "Posicionar uma das alças na nuca e a outra na cabeça.",
        "Ajustar o clip nasal no nariz.",
        "Verificar a vedação pelo teste de pressão positiva e negativa."
    ]

    const obsItems = [
        "Expire profundamente. Uma pressão positiva dentro da máscara significa que não tem vazamento. ",
        "Se houver vazamento, ajuste a posição e/ou as alças de tensão. Teste novamente a vedação. ",
        "Repita os passos até que a máscara esteja vedando corretamente! ",
        "Inspire profundamente. Se não houver vazamento, a pressão negativa fará o respirador agarrar-se no seu rosto. ",
        "O vazamento resultará em perda de pressão negativa na máscara devido à entrada de ar através de lacunas na vedação.",
    ]

    return (
        <Container>
            <Title>Técnica da colocação da máscara de proteção respiratória tipo N95, N99, N100, PFF2 ou PFF</Title>
            
            <List items={items} />

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/7GgS4llDwsA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img21} />
            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img22} />
            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img23} />
            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img24} />
            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img25} />
            <img className="img-fluid mb-3 col-6" alt="Máscara Respiratória" src={img26} />

            <SubTitle>Observação:</SubTitle>

            <List items={obsItems} />

            <BackButton/>
        </Container>
    )
}