import styled from 'styled-components'

const Title = styled.h1`
    
`
const Background = styled.div`
    /* background-color: #78C2FF; */
    /* color: #78C2FF; */
`

export {
    Title,
    Background
}