import React from 'react'
import {
    Title,
    BackButton,
    List
} from '../../components'
import { Container } from './styles'

import img37 from '../../assets/img/img37.jpg'
import img38 from '../../assets/img/img38.jpg'

export default function (){

    const items = [
        "Colocar o gorro ou a touca na cabeça, começando pela testa em direção à base da nuca. ",
        "Adaptar na cabeça de modo confortável, cobrindo todo o cabelo e as orelhas. ",
        "Sempre que o gorro ou a touca aparentarem sinais de umidade, devem ser substituídos por outro/a.",
    ]

    return (
        <Container>
            <Title>Técnica de uso da touca</Title>
            
            <List items={items} />

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/TWaG1GjTiuk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <img className="img-fluid mb-3 col-6" alt="Uso de Touca" src={img37} />
            <img className="img-fluid mb-3 col-6" alt="Uso de Touca" src={img38} />

            <BackButton/>
        </Container>
    )
}