import React from 'react'
import { BackButton, SubTitle, Title } from '../../components'
import { Container } from './styles'

import algoritmo1 from '../../assets/img/algoritmo1.png'
import algoritmo2 from '../../assets/img/algoritmo2.png'
import algoritmo3 from '../../assets/img/algoritmo3.png'

export default function (){
    return (
        <Container>
            <Title>Algoritmos</Title>

            <SubTitle>Algoritmo para orientar profissionais da saúde na utilização da técnica da paramentação dos equipamentos de proteção individual durante a pandemia do SARS-CoV-2.</SubTitle>

            <img className="img-fluid mt-3 mb-3" src={algoritmo1} alt="Algoritmo" />

            <SubTitle>Algoritmo para orientar profissionais da saúde na utilização da técnica da desparamentação dos equipamentos de proteção individual durante a pandemia do SARS-CoV-2.</SubTitle>

            <img className="img-fluid mt-3 mb-3" src={algoritmo2} alt="Algoritmo" />

            <SubTitle>Algoritmo para prevenir lesão causada pelo uso dos equipamentos de proteção individual  na pandemia por SARS-CoV-2.</SubTitle>

            <img className="img-fluid mt-3 mb-3" src={algoritmo3} alt="Algoritmo" />

            <BackButton />
        </Container>
    )
}