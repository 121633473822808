import React from 'react'
import { 
    Author,
    Container,
    Background
} from './style'

import { useHistory } from 'react-router-dom'
import homeimage from '../../assets/home.png'

export default function(){
    const history = useHistory()
    return (
        <Background>
            <img src={homeimage} className="img-fluid" />
            <Container className="pt-5 mt-2 pb-2 text-center">
                <Author className="pt-2 pb-2 text-uppercase">Autores</Author>
                <Author className="pt-2 pb-2">Marcus Vinicius Teixeira de Almeida</Author>
                <Author className="pt-2 pb-2">Geraldo Majela Salomé</Author>
                <Author className="pt-2 pb-2">Breno César Diniz Pontes</Author>
                <Author className="pt-2 pb-2">Adriana Rodrigues dos Anjos Mendonça </Author>
            </Container>
            <div className="text-center pb-5">
                <button className="btn btn-info btn-lg btn-block" onClick={() => {
                    history.push("/sumario")
                }}>Iniciar</button>
            </div>
        </Background>
    )
}