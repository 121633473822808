import React from 'react'
import {
    Title,
    BackButton,
    List
} from '../../components'
import { Container } from './styles'

import img78 from '../../assets/img/img78.jpg'
import img80 from '../../assets/img/img80.jpg'
import img81 from '../../assets/img/img81.jpg'
import img82 from '../../assets/img/img82.jpg'
import img83 from '../../assets/img/img83.jpg'
import img85 from '../../assets/img/img85.jpg'
import img86 from '../../assets/img/img86.jpg'
import img87 from '../../assets/img/img87.jpg'
import img88 from '../../assets/img/img88.jpg'




export default function (){

    const items = [
        "Durante a retirada do avental ou capote, evite tocar o lado externo, pois estará contaminado.",
        "Abra as tiras e solte as amarras. ",
        "Empurre pelo pescoço e pelos ombros, tocando apenas a parte interna do avental/capote. ",
        "Retire o avental/capote pelo avesso. ",
        "Dobre ou enrole em uma trouxa e descarte em recipiente apropriado.",
        "Higienize as mãos com água e sabão ou com solução alcoólica a 70%.",
    ]

    return (
        <Container>
            <Title>Técnica de retirada do avental</Title>
            
            <List items={items} />

            <iframe width="100%" height="315" src="https://www.youtube.com/embed/Kbx56B5BVAE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img78} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img80} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img81} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img82} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img83} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img85} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img86} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img87} />
            <img className="img-fluid mb-3 col-6" alt="Óculos" src={img88} />


            <BackButton/>
        </Container>
    )
}